import React from 'react';

const UxUiBoost = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46 48C45.7379 47.9932 45.4891 47.9185 45.3037 47.791C45.1183 47.6635 45.0099 47.4925 45 47.3123V17.6877C45 17.5053 45.1054 17.3304 45.293 17.2015C45.4805 17.0725 45.7348 17 46 17C46.2652 17 46.5195 17.0725 46.707 17.2015C46.8946 17.3304 47 17.5053 47 17.6877V47.3123C47 47.4947 46.8946 47.6696 46.707 47.7985C46.5195 47.9275 46.2652 48 46 48Z"
      fill="#FCFCFD"
    />
    <path
      d="M39 48C38.7348 48 38.4805 47.9127 38.293 47.7574C38.1054 47.6021 38 47.3915 38 47.1719V27.8281C38 27.6085 38.1054 27.3979 38.293 27.2426C38.4805 27.0873 38.7348 27 39 27C39.2652 27 39.5195 27.0873 39.707 27.2426C39.8946 27.3979 40 27.6085 40 27.8281V47.1719C40 47.3915 39.8946 47.6021 39.707 47.7574C39.5195 47.9127 39.2652 48 39 48Z"
      fill="#FCFCFD"
    />
    <path
      d="M32 48C31.7348 48 31.4805 47.9126 31.293 47.757C31.1054 47.6015 31 47.3906 31 47.1706V34.8294C31 34.6094 31.1054 34.3985 31.293 34.243C31.4805 34.0874 31.7348 34 32 34C32.2652 34 32.5195 34.0874 32.707 34.243C32.8946 34.3985 33 34.6094 33 34.8294V47.1706C33 47.3906 32.8946 47.6015 32.707 47.757C32.5195 47.9126 32.2652 48 32 48Z"
      fill="#FCFCFD"
    />
    <path
      d="M25 48C24.7379 47.9923 24.4891 47.9084 24.3037 47.765C24.1183 47.6216 24.0099 47.4294 24 47.2268V39.7732C24.0099 39.5706 24.1183 39.3784 24.3037 39.235C24.4891 39.0916 24.7379 39.0077 25 39C25.2652 39 25.5195 39.0815 25.707 39.2265C25.8946 39.3715 26 39.5681 26 39.7732V47.2268C26 47.4319 25.8946 47.6285 25.707 47.7735C25.5195 47.9185 25.2652 48 25 48Z"
      fill="#FCFCFD"
    />
    <path
      d="M17 48C16.7348 48 16.4795 47.9091 16.292 47.7474C16.1045 47.5858 16 47.3666 16 47.1379V41.8621C16 41.6334 16.1045 41.4142 16.292 41.2526C16.4795 41.0909 16.7348 41 17 41C17.2652 41 17.5195 41.0909 17.707 41.2526C17.8946 41.4142 18 41.6334 18 41.8621V47.1379C18 47.3666 17.8946 47.5858 17.707 47.7474C17.5195 47.9091 17.2652 48 17 48Z"
      fill="#FCFCFD"
    />
    <path
      d="M10 48C9.73478 48 9.47953 47.9121 9.29199 47.7559C9.10446 47.5996 9 47.3877 9 47.1667V43.8333C9 43.6123 9.10446 43.4004 9.29199 43.2441C9.47953 43.0879 9.73478 43 10 43C10.2652 43 10.5195 43.0879 10.707 43.2441C10.8946 43.4004 11 43.6123 11 43.8333V47.1667C11 47.3877 10.8946 47.5996 10.707 47.7559C10.5195 47.9121 10.2652 48 10 48Z"
      fill="#FCFCFD"
    />
    <path
      d="M2 48C1.73794 47.9919 1.48914 47.9029 1.30371 47.7509C1.11828 47.5989 1.0099 47.3951 1 47.1803V44.8197C1 44.6023 1.10446 44.3938 1.29199 44.2401C1.47953 44.0864 1.73478 44 2 44C2.26522 44 2.51949 44.0864 2.70703 44.2401C2.89457 44.3938 3 44.6023 3 44.8197V47.1803C3 47.3977 2.89457 47.6062 2.70703 47.7599C2.51949 47.9136 2.26522 48 2 48Z"
      fill="#FCFCFD"
    />
    <path
      d="M3.35859 39.9999C1.81232 39.9999 0.858119 39.9999 0.759421 39.9999C0.651287 39.9915 0.546032 39.9616 0.449385 39.9118C0.352738 39.8621 0.266704 39.7935 0.196376 39.71C0.126049 39.6266 0.0729672 39.53 0.0397518 39.4256C0.00653636 39.3213 -0.00619002 39.2113 0.00280445 39.1021C0.011107 38.9927 0.0411471 38.8861 0.0903535 38.7884C0.13956 38.6907 0.206989 38.6038 0.289548 38.5327C0.372107 38.4616 0.468231 38.4077 0.571472 38.3741C0.674713 38.3405 0.783068 38.3279 0.891147 38.337C7.78172 38.6907 14.6683 37.5569 21.0917 35.0111C34.7449 29.5234 42.5743 18.3817 44.3508 1.75239C44.3727 1.53187 44.4808 1.32921 44.6504 1.18887C44.7344 1.11939 44.8308 1.06731 44.9348 1.03561C45.0387 1.00392 45.148 0.993229 45.2561 1.00415C45.3641 1.01507 45.4688 1.04728 45.5645 1.09915C45.6601 1.15102 45.7447 1.22148 45.8135 1.30641C45.8822 1.39134 45.9339 1.4892 45.9653 1.59426C45.9966 1.69931 46.0066 1.80945 45.9958 1.91864C43.66 23.4703 31.4876 32.6831 21.6507 36.6077C15.8104 38.8617 9.60998 40.0114 3.35859 39.9999Z"
      fill="#00FFC2"
    />
    <path
      d="M47.2154 3.97428C47.0979 3.97328 46.9819 3.94635 46.8757 3.8953C46.7695 3.84425 46.6759 3.77043 46.6007 3.67879L45.1446 1.83951L43.3645 3.31735C43.2919 3.3959 43.2043 3.45859 43.1069 3.50138C43.0095 3.54418 42.9041 3.56633 42.798 3.56633C42.6919 3.56633 42.5865 3.54418 42.4891 3.50138C42.3917 3.45859 42.3041 3.3959 42.2315 3.31735C42.0833 3.16386 42 2.95754 42 2.74262C42 2.52769 42.0833 2.32137 42.2315 2.16788L44.6587 0.19729C44.8367 0.0647198 45.0526 -0.00454062 45.2734 0.000231023C45.3777 0.0147095 45.478 0.0514662 45.5673 0.108082C45.6565 0.164698 45.7332 0.239806 45.7917 0.328595L47.7653 2.75905C47.9157 2.91807 48 3.12987 48 3.35023C48 3.57059 47.9157 3.78239 47.7653 3.94141C47.5889 4.00606 47.3981 4.0175 47.2154 3.97428Z"
      fill="#00FFC2"
    />
  </svg>
);

export default UxUiBoost;
